@import url(https://fonts.googleapis.com/css?family=Lobster+Two:700|Fira+Sans&display=swap);
body, #root, #app {
  height: 100vh;
  width: 100vw;
  margin: 0; }

* {
  box-sizing: border-box; }

a {
  color: inherit;
  text-decoration: inherit; }

.header {
  height: 15vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5vw;
  font-family: "Lobster Two", cursive;
  background-color: #F2F3FC;
  color: #12131C; }
  .header .name {
    font-size: 2rem; }
  .header .title {
    font-size: 2.6rem; }
  .header .links {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around; }
    .header .links .link {
      margin: 0 .6rem; }

.accordion {
  height: 85vh;
  font-family: "Fira Sans", sans-serif;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between; }
  .accordion .section {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 1.5rem 0 0 0;
    height: 100%;
    width: 10%;
    background: #12131C;
    color: #F2F3FC;
    transition: width .6s; }
    .accordion .section .section-title {
      color: #707072;
      font-size: 2.5rem;
      position: relative;
      transform: rotate(-90deg);
      top: 30%; }
    .accordion .section.open {
      width: 70%;
      background: #707072; }
      .accordion .section.open .section-title {
        color: #12131C;
        left: 20%;
        top: 0;
        transform: rotate(0deg);
        margin: 0 0 1rem 0; }
        .accordion .section.open .section-title .accent-bar {
          background: #0296E0;
          height: .5rem;
          width: 8rem; }
    .accordion .section .content {
      opacity: 0;
      display: flex;
      flex-flow: wrap;
      justify-content: space-around;
      overflow: scroll;
      animation: 0.6s linear forwards opencontent; }
      .accordion .section .content .project-card {
        flex-grow: 1;
        max-width: 300px;
        max-height: 260px;
        padding: 1rem;
        display: flex;
        flex-flow: column;
        justify-content: space-between; }
        .accordion .section .content .project-card .project-description {
          margin: 1rem 0; }
        .accordion .section .content .project-card .links {
          display: flex;
          justify-content: space-around; }
      .accordion .section .content .news-article {
        width: 80%; }
      .accordion .section .content .about {
        width: 80%; }
      .accordion .section .content.open {
        opacity: 1; }

@keyframes opencontent {
  from {
    opacity: 0; }
  85% {
    opacity: 0; }
  to {
    opacity: 1; } }

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
    position: relative;
    height: auto;
    width: auto; }
    .header div {
      margin: .4rem 0rem; }
  .accordion {
    flex-direction: column;
    position: relative;
    height: auto;
    width: auto; }
    .accordion .section {
      width: 100vw; }
      .accordion .section:nth-child(2) {
        order: -1; }
      .accordion .section:last-child {
        padding-bottom: 5rem; }
      .accordion .section .section-title {
        transform: rotate(0deg); }
      .accordion .section.open {
        width: 100vw; }
      .accordion .section .content {
        max-height: 70vh; } }
