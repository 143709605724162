.game-window {
  width: 400px;
  height: 500px;
  background: #12131C;
  position: relative;
  overflow: hidden;
}

.ship {
  position: absolute;
}

.asteroid {
  position: absolute;
}

.overlay {
  position: absolute;
  background: #12131C;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
